import {
  ADMIN,
  ADMIN_COMPETITION,
  EXPERT,
  MANAGER,
  MONITOR,
  WINNER,
  MONITOR_CONTENT,
  USER,
} from '@/enums/roles'

export const roles = {
  [MANAGER]: [
    'programs-list',
    'not-found',
    'not-found',
    'tasks-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'create-project-by-code-1c',
    'project-preview',
    'edit-project',
    'view-project',
    'diagnostics',
    'diagnostics-list',
    'projects',
    'projects-list',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
  [ADMIN]: [
    'usersList',
    'diagnostics-list',
    'personal-account',
    'callback',
    'default',
    'diagnostics',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
  [ADMIN_COMPETITION]: [],
  [MONITOR]: [
    'tasks-list',
    'diagnostics-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'diagnostics',
    'projects',
    'projects-list',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
  [EXPERT]: [
    'programs-list',
    'programs-view-data',
    'tasks-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'project-preview',
    'edit-project',
    'view-project',
    'diagnostics',
    'diagnostics-list',
    'projects',
    'projects-list',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
  [WINNER]: [],
  [USER]: [
    'programs-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'tasks-list',
    'programs-view-data',
    'create-project-by-code-1c',
    'project-preview',
    'edit-project',
    'view-project',
    'diagnostics',
    'diagnostics-list',
    'projects',
    'projects-list',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
  [MONITOR_CONTENT]: [
    'tasks-list',
    'diagnostics',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'diagnostics-list',
    'projects',
    'projects-list',
    'project-by-id',
    'not-found-page',
    'not-found',
  ],
}
