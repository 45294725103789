<template>
  <div class="not-found">
    <div class="error-code">{{ error || '404' }}</div>
    <h1>{{ error ? 'Нет доступа' : 'Страница не найдена' }}</h1>
  </div>
</template>
<script setup>
import { nextTick, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const error = ref('')

onMounted(() => {
  nextTick(() => {
    if (route.params?.code) {
      error.value = route.params?.code
    }
  })
})
</script>
<style lang="scss">
.not-found {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 0;
  }
}

.error-code {
  font-size: 70px;
  color: var(--primary);
  font-weight: 600;
}
</style>
