<template>
  <div class="diagnostics-view task-page">
    <v-card>
      <v-card-text>
        <v-alert v-if="error" type="error" variant="tonal" class="mb-4">
          {{ error }}
        </v-alert>
        <ul style="list-style-type: none" class="pa-0">
          <li class="d-flex align-center py-1">
            <span :class="[getIsLoadClass(pluginLoaded), 'mr-2 circle']" />
            <span class="text-body-1"> Плагин загружен</span>
          </li>
          <li class="d-flex align-center py-1">
            <span :class="[getIsLoadClass(pluginWorking), 'mr-2 circle']" />
            <span class="text-body-1">Плагин работает</span>
          </li>
          <li class="d-flex align-center py-1">
            <span :class="[getIsLoadClass(objectsEnumerated), 'mr-2 circle']" />
            <span class="text-body-1">Криптопровайдер загружен</span>
          </li>
          <li class="d-flex align-center py-1">
            <span :class="[getIsLoadClass(cryptoProviderLoaded), 'mr-2 circle']" />
            <span class="text-body-1">Перечисление объектов плагина завершено</span>
          </li>
          <li v-if="pluginVersion">Версия плагина: {{ pluginVersion }}</li>
        </ul>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Выберите сертификат:</v-card-title>
      <v-card-text>
        <v-radio-group v-model="selectedCertificate" column>
          <template v-if="certificates.length">
            <v-row
              v-for="(cert, index) in certificates"
              :key="index"
              class="align-center ma-0 py-1 w-100"
            >
              <v-icon :color="cert.isNotYet ? 'green' : 'red'">{{
                cert.isNotYet ? 'mdi-check' : 'mdi-close'
              }}</v-icon>
              <v-radio
                :disabled="!cert.isNotYet"
                class="ml-2"
                :value="cert.thumbprint"
                :label="cert.label"
              />
            </v-row>
          </template>
          <v-row v-else class="align-center ma-0 py-1 w-100 text-center text-grey justify-center"
            >Нет доступных сертификатов</v-row
          >
        </v-radio-group>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Данные для подписи:</v-card-title>
      <v-card-text>
        <v-textarea v-model="textToSign" label="Введите данные для подписи"></v-textarea>
        <v-btn
          color="primary"
          :disabled="
            !selectedCertificate ||
            !pluginLoaded ||
            !pluginWorking ||
            !objectsEnumerated ||
            !cryptoProviderLoaded ||
            !textToSign
          "
          class="mt-2"
          @click="signDocument"
          >Подписать</v-btn
        >
      </v-card-text>
    </v-card>

    <v-card v-if="currentCertificate" class="mt-4">
      <v-card-title>Информация о сертификате:</v-card-title>
      <v-card-text>
        <p>Владелец: {{ currentCertificate.name }}</p>
        <p>Издатель: {{ currentCertificate.issuerName }}</p>
        <p>Срок действия: {{ currentCertificate.validTo }}</p>
        <p>
          Статус:
          <span :class="currentCertificate.isNotYet ? '' : 'text-red'">{{
            currentCertificate.status
          }}</span>
        </p>
      </v-card-text>
    </v-card>
    <v-card v-if="sign" class="mt-4">
      <v-card-title>Подпись сформирована успешно:</v-card-title>
      <v-card-text>
        <v-textarea v-model="sign" />
        <v-row class="mx-0 my-2 pa-0 justify-end">
          <v-btn color="primary" prepend-icon="mdi-content-copy" class="mt-2" @click="copySign"
            >Копировать</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup>
import store from '@/store'
import { computed, onMounted, ref } from 'vue'
import { createDetachedSignature, getUserCertificates, createHash } from 'crypto-pro'
import moment from 'moment'
import { getFormatDateTime } from '@/helpers'
import { useToast } from '@/composables/useToast'
import { api } from '@/api/Api'

const { toast } = useToast()

const certificates = ref([])
const selectedCertificate = ref('')
const textToSign = ref('Hello World')
const error = ref('')
const pluginLoaded = ref(false)
const pluginVersion = ref('')
const pluginWorking = ref(false)
const cryptoProviderLoaded = ref(false)
const objectsEnumerated = ref(false)
const officialCertificate = ref([])
const sign = ref()

const currentCertificate = computed(
  () => certificates.value?.find((el) => el.thumbprint === selectedCertificate.value) || null,
)

const getIsLoadClass = (value) => {
  return value ? 'green' : 'red'
}

const copySign = () => {
  navigator.clipboard
    .writeText(sign.value)
    .then(() => {
      toast.success('Подпись скопирована в буфер обмена')
    })
    .catch((err) => {
      console.error('Ошибка при копировании в буфер обмена: ', err)
    })
}
const checkCadesPlugin = async () => {
  await window.cadesplugin.then(() => {
    pluginLoaded.value = true
    pluginWorking.value = true
  })
  const cadesStore = await window.cadesplugin.CreateObjectAsync('CAdESCOM.Store')

  await cadesStore.Open()
  const certificates = await cadesStore.Certificates
  const count = await certificates.Count

  if (count > 0) {
    cryptoProviderLoaded.value = true
    objectsEnumerated.value = true
  } else {
    throw new Error('Сертификаты не найдены.')
  }

  await cadesStore.Close()
  try {
    if (!window.cadesplugin) {
      throw new Error('Плагин CryptoPro не загружен. Убедитесь, что он установлен.')
    }
  } catch (e) {
    error.value = e.message
    pluginWorking.value = false
  }
}

const fetchCertificates = async () => {
  try {
    sign.value = ''
    const certs = await getUserCertificates()
    officialCertificate.value = certs
    certificates.value = certs.map((cert) => ({
      ...cert,
      label: `${cert.name || ''}; Выдан: ${moment(cert.validFrom).format(
        'DD.MM.YYYY HH:mm',
      )}; Действует до: ${moment(cert.validTo).format('DD.MM.YYYY HH:mm')}`,
      thumbprint: cert.thumbprint,
      details: cert,
      isNotYet: moment().isBefore(moment(cert.validTo, 'YYYY-MM-DD HH:mm')),
      subjectName: cert.subjectName,
      issuerName: cert.issuerName,
      validFrom: getFormatDateTime(cert.validFrom),
      validTo: getFormatDateTime(cert.validTo),
      status: moment().isBefore(moment(cert.validTo, 'YYYY-MM-DD HH:mm'))
        ? 'Действителен'
        : 'Срок действия истек',
    }))
    error.value = ''
  } catch (e) {
    error.value = `Ошибка получения сертификатов: ${e.message}`
  }
}

async function hasPrivateKey(certificate) {
  try {
    const hasKey = await certificate._cadesCertificate?.HasPrivateKey()
    return hasKey
  } catch (error) {
    console.error('Ошибка проверки привязанного ключа:', error?.message || error)
    return false
  }
}

async function isCertificateTrusted(certificate) {
  try {
    const validationResult = await certificate?._cadesCertificate?.IsValid()
    const isValid = await validationResult.Result
    return isValid
  } catch (error) {
    console.error('Ошибка проверки доверия сертификату:', error?.message || error)
    return false
  }
}

const signDocument = async () => {
  sign.value = ''
  try {
    if (!selectedCertificate.value) {
      throw new Error('Выберите сертификат для подписи.')
    }

    const certificates = await getUserCertificates()
    if (!certificates.length) {
      throw new Error('Нет доступных сертификатов для подписи.')
    }

    const currentCertificate = officialCertificate.value.find(
      (cert) => cert.thumbprint === selectedCertificate.value,
    )
    if (!currentCertificate?.thumbprint) {
      throw new Error('Нет доступных сертификатов для подписи.')
    }

    const signOptions = {
      detached: true,
      includeWholeChain: true,
    }
    const isValidPrivateKey = await hasPrivateKey(currentCertificate)
    const isCertTrusted = await isCertificateTrusted(currentCertificate)

    if (!isValidPrivateKey) {
      throw new Error('Ошибка проверки цепочки доверия. Попробуйте использовать другой сертификат')
    }

    if (!isCertTrusted) {
      throw new Error(
        'Ошибка приватного ключа сертификата. Попробуйте использовать другой сертификат',
      )
    }

    const hash = await createHash(textToSign.value)
    const signature = await createDetachedSignature(
      currentCertificate.thumbprint,
      hash,
      signOptions,
    )
    error.value = ''
    sign.value = signature
  } catch (err) {
    sendFeedbackForm(window?.lastErrorObject)
    error.value = `Ошибка подписи: ${err?.message || err}`
  }
}

const sendFeedbackForm = async (err) => {
  try {
    const formData = new FormData()
    formData.append('theme', `${process.env.VUE_APP_TECHNICAL_PROBLEM_CODE}`)
    formData.append('project', process.env.VUE_APP_PROJECT_ID)
    formData.append('subject', 'Диагностика создания электронной подписи')
    const message = `Владелец: ${currentCertificate.value?.name}\nИздатель: ${
      currentCertificate.value?.issuerName
    }\nСрок действия: ${currentCertificate.value.validTo}\nСтатус: ${
      currentCertificate.value.status
    }\nОшибка: ${err?.message || err || ''}`
    formData.append('message', message)
    api
      .feedback()
      .sendFeedbackForm(formData)
      .then(() => {})
      .catch((err) => {
        if (err?.message || err) {
          toast.error(err?.message || err)
        }
      })
  } catch (err) {
    console.log(err)
  }
}

const setGlobalError = () => {
  const originalConsoleError = console.error
  console.error = function (...args) {
    originalConsoleError.apply(this, args)

    if (args[0] && typeof args[0] === 'object') {
      window.lastErrorObject = args[0]
    }
  }
}

const setPageTitle = () => {
  fetchCertificates()
  checkCadesPlugin()
  store.commit('app/setPageTitle', 'Диагностика')
  setGlobalError()
}

onMounted(setPageTitle)
</script>
<style lang="scss" scoped>
.diagnostics-view {
  flex: 1;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
}
</style>
