<template>
  <div class="action-panel filters">
    <div class="input-wrapper">
      <SearchIcon />
      <input
        v-model="name"
        type="text"
        class="search"
        placeholder="Поиск по разделу"
        @change="emit('name-change', name)"
      />
    </div>
    <v-select
      v-if="pointType !== 'notes'"
      :model-value="type"
      variant="outlined"
      :items="templatesOptions"
      placeholder="Все статусы"
      item-title="label"
      item-value="id"
      class="select"
      clearable
      @update:model-value="handleChangeValue"
    />
    <v-select
      :model-value="pointType"
      variant="outlined"
      clearable
      :items="pointOptions"
      placeholder="Все задачи"
      item-title="label"
      item-value="name"
      class="select"
      @update:model-value="handleChangePointsType"
    />
  </div>
</template>
<script setup>
import SearchIcon from '@/components/Icons/SearchIcon.vue'
import { computed, nextTick, onMounted, ref } from 'vue'
import { STATUSES } from '@/constants/points'
import { useStore } from 'vuex'

const store = useStore()

const emit = defineEmits(['name-change', 'point-type-change'])

const isExpert = computed(() => store.getters['user/isExpert'])

const type = ref()
const name = ref()
const pointType = ref({
  label: 'Требуют действий',
  name: 'active',
})

const templatesOptions = ref(STATUSES)
const pointOptions = ref([
  {
    label: 'Требуют действий',
    name: 'active',
  },
  {
    label: 'Отработаны',
    name: 'closed',
  },
  {
    name: 'expired',
    label: 'Просроченные',
  },
  {
    name: 'notes',
    label: 'Черновики',
  },
])

const handleChangeValue = (val) => {
  emit('type-change', val)
  type.value = val
}

const handleChangePointsType = (val) => {
  pointType.value = val
  emit('point-type-change', val)
}

onMounted(() => {
  nextTick(() => {
    if (isExpert.value) {
      pointOptions.value.push({ label: 'Экспертиза', name: 'expert' })
    }
  })
})
</script>
<style lang="scss" scoped>
.action-panel {
  padding: 12px 24px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);
  margin-bottom: 24px;

  .select {
    max-width: 240px;

    @media (max-width: 567px) {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .checkbox {
    margin-bottom: 0;
    max-width: 136px;

    .v-label {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.action-panel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 30px;

  @media (max-width: 765px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  @media (max-width: 567px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .input-wrapper {
    background: var(--bage-color);
    border: 1px solid var(--bage-color);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    height: 40px;
    border-radius: 8px;
    flex: 1;
    max-width: 640px;

    @media (max-width: 567px) {
      margin-bottom: 10px;
    }

    input {
      height: 100%;
      min-height: 40px;
      display: block;
      flex: 1;
      font-size: 14px;
    }
  }

  .v-input--density-default {
    --v-input-control-height: 40px;
    --v-input-padding-top: 12px;
  }

  .v-input__details {
    display: none;
  }

  .checkbox {
    .v-icon--size-default {
      font-size: 24px;
    }
  }

  .select {
    max-width: 194px;
    width: 100%;

    @media (max-width: 765px) {
      max-width: 100%;
    }

    .v-field {
      border-radius: 8px;
    }
    .v-field__input {
      padding: 10px 12px;
      overflow: hidden;

      span {
        font-size: 14px;
        line-height: 20px;
        white-space: pre;
      }
    }
  }
}
</style>
